import { createContext, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { useChainId } from "wagmi";
import BigNumber from "bignumber.js";
import axios from "axios";
import io from "socket.io-client";

import "./App.css";
import SideBar from "./components/SideBar";
import NavBar from "./components/NavBar";
import LandingPage from "./pages/LandingPage";
import DashboardPage from "./pages/DashboardPage";
import DeployPage from "./pages/DeployPage";
import LiquidityPage from "./pages/LiquidityPage";
import EstimatePage from "./pages/EstimatePage";
import BuyPage from "./pages/BuyPage";
import SellPage from "./pages/SellPage";
import TransferPage from "./pages/TransferPage";
import MetricPage from "./pages/MetricPage";
import SignupPage from "./pages/SignupPage";
import SigninPage from "./pages/SigninPage";

import LoadingDialog from "./components/Dialogs/LoadingDialog";
import { useEthersProvider } from "./utils/provider";
import { sleep } from "./utils/methods";
import tokenABI from "./abi/ITradingToken.json";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const AppContext = createContext(null);

let ws;

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const chainId = useChainId();
    const provider = useEthersProvider(chainId);

    // const [scrolled, setScrolled] = useState(false);
    const [loadingPrompt, setLoadingPrompt] = useState("");
    const [openLoading, setOpenLoading] = useState(false);

    const [user, setUser] = useState(null);
    const [nativeCurrency, setNativeCurrency] = useState("ETH");
    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState([]);
    // enableTeamZombieWallet (true/false)
    // enableBuyTeamTokens (true/false)
    // enableBuyTeamTokensRandomly (true/false)
    // enableBuyTeamTokensWithBot (true/false)
    // teamTotalTokenPercent (5)
    // teamWalletTokenPercent (1) x
    // extraWalletTokenPercent (0.5) x
    // enableBuyExtraTokensWithBot (true/false)
    // enableTokenSniper (true/false)
    const [settings, setSettings] = useState([]);
    const [currentProject, setCurrentProject] = useState({});
    const [webSocket, setWebSocket] = useState(null);
    const [notifyStatus, setNotifyStatus] = useState({
        success: true,
        tag: "NONE",
    });
    const [antiDrainers, setAntiDrainers] = useState([]);
    const [disperseContract, setDisperseContract] = useState({});
    const [extraWallets, setExtraWallets] = useState([]);
    const [emails, setEmails] = useState([]);
    const [walletBalanceData, setWalletBalanceData] = useState({
        address: "",
        token: [],
        eth: [],
    });
    const [teamWalletBalanceData, setTeamWalletBalanceData] = useState({
        address: "",
        token: [],
        eth: [],
    });
    const [breadCrumb, setBreadCrumb] = useState("");

    const openWebSocket = (userId) => {
        console.log("Starting websocket...");
        ws = new io(SERVER_URL);
        ws.on("connect", () => {
            console.log("WebSocket connection established : ", userId);
            ws.emit("NEW_USER", userId);
        });

        ws.on("BUY_PENDING", async (value) => {
            setNotifyStatus({ success: true, tag: "BUY_PENDING" });
        });

        ws.on("DIRTY_WALLET_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({
                    success: true,
                    tag: "DIRTY_WALLET_COMPLETED",
                });
            else
                setNotifyStatus({
                    success: false,
                    tag: "DIRTY_WALLET_COMPLETED",
                });
        });

        ws.on("SIMULATE_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({
                    success: true,
                    tag: "SIMULATE_COMPLETED",
                    data: m.data,
                });
            else
                setNotifyStatus({
                    success: false,
                    tag: "SIMULATE_COMPLETED",
                    error: m.error,
                });
        });

        ws.on("BUY_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({
                    success: true,
                    tag: "BUY_COMPLETED",
                    project: m.project,
                });
            else setNotifyStatus({ success: false, tag: "BUY_COMPLETED" });
        });

        ws.on("SELL_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            setNotifyStatus({
                success: m.message === "OK",
                tag: "SELL_COMPLETED",
                project: m.project,
            });
        });

        ws.on("TRANSFER_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            setNotifyStatus({
                success: m.message === "OK",
                tag: "TRANSFER_COMPLETED",
                project: m.project,
            });
        });

        ws.on("COLLECT_ALL_ETH", async (value) => {
            const m = JSON.parse(value);
            setNotifyStatus({
                success: m.message === "OK",
                tag: "COLLECT_ALL_ETH",
                project: m.project,
            });
        });

        ws.on("COLLECT_ALL_FEE", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "COLLECT_ALL_FEE" });
            else setNotifyStatus({ success: false, tag: "COLLECT_ALL_FEE" });
        });

        ws.on("NEW_METRICS", async (value) => {
            const m = JSON.parse(value);
            console.log("New metrics", m.userId, m.metrics);
            setNotifyStatus({
                success: true,
                tag: "NEW_METRICS",
                userId: m.userId,
                metrics: m.metrics,
            });
        });

        ws.on("LOG", (value) => {
            console.log("SERVER:", value);
        });

        ws.on("disconnect", () => {
            console.log("WebSocket connection closed");
            // setConnected(false);
        });

        setWebSocket(ws);
    };

    const closeWebSocket = () => {
        if (webSocket) webSocket.close();
        setWebSocket(null);
    };

    const updateAllBalances = async (provider, token, wallets, teamWallets) => {
        console.log("Updating all balances...", token, wallets, teamWallets);

        const maxCount = 10;
        let tokenBalances = [];
        let ethBalances = [];
        let teamTokenBalances = [];
        let teamEthBalances = [];
        try {
            const tokenContract = new ethers.Contract(
                token,
                tokenABI,
                provider
            );
            const decimals = await tokenContract.decimals();
            console.log("Deciamls:", decimals);
            // const balance1 = await tokenContract.balanceOf("0x8c9286226f4f98f10df71111c52069DC312a4bF5");
            // console.log("Balance:", balance1);

            // for (let i = 0; i < wallets.length; i++) {
            //     try {
            //         const weiBalance = await tokenContract.balanceOf(wallets[i]);
            //         tokenBalances = [
            //             ...tokenBalances,
            //             Number(new BigNumber(weiBalance.toString() + "e-" + decimals.toString()).toString()).toFixed(4)
            //         ];
            //     }
            //     catch (err) {
            //         console.log("Error: Wallet", i, wallets[i], err);
            //     }
            // }

            let index = 0;
            while (index < wallets.length) {
                try {
                    let count = wallets.length - index;
                    if (count > maxCount) count = maxCount;

                    let pendings = [];
                    for (let i = index; i < index + count; i++) {
                        pendings = [
                            ...pendings,
                            tokenContract.balanceOf(wallets[i]),
                        ];
                    }

                    const weiBalances = await Promise.all(pendings);
                    tokenBalances = [
                        ...tokenBalances,
                        ...weiBalances.map((item) =>
                            Number(
                                new BigNumber(
                                    item.toString() + "e-" + decimals.toString()
                                ).toString()
                            ).toFixed(4)
                        ),
                    ];
                    index += count;
                } catch (err) {
                    console.log(err, "Wallet", index);
                    await sleep(1000);
                }
            }

            if (teamWallets) {
                // for (let i = 0; i < teamWallets.length; i++) {
                //     try {
                //         const weiBalance = await tokenContract.balanceOf(teamWallets[i]);
                //         teamTokenBalances = [
                //             ...teamTokenBalances,
                //             Number(new BigNumber(weiBalance.toString() + "e-" + decimals.toString()).toString()).toFixed(4)
                //         ];
                //     }
                //     catch (err) {
                //         console.log("Error: Team Wallet", i, teamWallets[i], err);
                //     }
                // }

                index = 0;
                while (index < teamWallets.length) {
                    try {
                        let count = teamWallets.length - index;
                        if (count > maxCount) count = maxCount;

                        let pendings = [];
                        for (let i = index; i < index + count; i++) {
                            pendings = [
                                ...pendings,
                                tokenContract.balanceOf(teamWallets[i]),
                            ];
                        }

                        const weiBalances = await Promise.all(pendings);
                        teamTokenBalances = [
                            ...teamTokenBalances,
                            ...weiBalances.map((item) =>
                                Number(
                                    new BigNumber(
                                        item.toString() +
                                            "e-" +
                                            decimals.toString()
                                    ).toString()
                                ).toFixed(4)
                            ),
                        ];
                        index += count;
                    } catch (err) {
                        console.log(err, "Team Wallet", index);
                        await sleep(1000);
                    }
                }
            }
        } catch (err) {
            console.log(err);
            tokenBalances = wallets.map(() => "0");
            teamTokenBalances = teamWallets ? teamWallets.map(() => "0") : [];
        }

        try {
            let index = 0;
            while (index < wallets.length) {
                try {
                    let count = wallets.length - index;
                    if (count > maxCount) count = maxCount;

                    let pendings = [];
                    for (let i = index; i < index + count; i++) {
                        pendings = [
                            ...pendings,
                            provider.getBalance(wallets[i]),
                        ];
                    }

                    const weiBalances = await Promise.all(pendings);
                    ethBalances = [
                        ...ethBalances,
                        ...weiBalances.map((item) =>
                            Number(
                                new BigNumber(
                                    item.toString() + "e-18"
                                ).toString()
                            ).toFixed(4)
                        ),
                    ];
                    index += count;
                } catch (err) {
                    console.log(err, "Wallet", index);
                    await sleep(1000);
                }
            }

            if (teamWallets) {
                index = 0;
                while (index < teamWallets.length) {
                    try {
                        let count = teamWallets.length - index;
                        if (count > maxCount) count = maxCount;

                        let pendings = [];
                        for (let i = index; i < index + count; i++) {
                            pendings = [
                                ...pendings,
                                provider.getBalance(teamWallets[i]),
                            ];
                        }

                        const weiBalances = await Promise.all(pendings);
                        teamEthBalances = [
                            ...teamEthBalances,
                            ...weiBalances.map((item) =>
                                Number(
                                    new BigNumber(
                                        item.toString() + "e-18"
                                    ).toString()
                                ).toFixed(4)
                            ),
                        ];
                        index += count;
                    } catch (err) {
                        console.log(err, "Team Wallet", index);
                        await sleep(1000);
                    }
                }
            }
        } catch (err) {
            console.log(err);
            ethBalances = wallets.map(() => "0");
            teamEthBalances = teamWallets ? teamWallets.map(() => "0") : [];
        }

        console.log("Updated all balances!");
        setWalletBalanceData({
            address: token,
            token: tokenBalances,
            eth: ethBalances,
        });
        setTeamWalletBalanceData({
            address: token,
            token: teamTokenBalances,
            eth: teamEthBalances,
        });
    };

    // const handleScroll = () => {
    //     const offset = window.scrollY;
    //     if (offset > 0)
    //         setScrolled(true);
    //     else
    //         setScrolled(false);
    // };

    const loadGlobalSettings = async () => {
        let newSettings = [];
        setLoadingPrompt("Loading global settings...");
        setOpenLoading(true);
        try {
            console.log("Loading global settings...");
            const { data } = await axios.get(
                `${SERVER_URL}/api/v1/misc/load-presets`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            // console.log("loadGlobalSettings log - 1 : ", data);
            if (data.settings) newSettings = data.settings;
        } catch (err) {
            console.log(err);
            toast.warn("Failed to load global settings");
        }

        setOpenLoading(false);
        setSettings(newSettings);
    };

    const loadAllProjects = async () => {
        let newProjects = [];
        setLoadingPrompt("Loading all projects...");
        setOpenLoading(true);
        try {
            console.log("Loading all projects...");
            const { data } = await axios.get(
                `${SERVER_URL}/api/v1/project/load-all`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            if (data.projects) newProjects = data.projects;
        } catch (err) {
            console.log(err);
            toast.warn("Failed to load projects");
        }

        setOpenLoading(false);
        setProjects(newProjects);
        setCurrentProject({});
    };

    const loadAllUsers = async () => {
        let newUsers = [];
        setLoadingPrompt("Loading all users...");
        setOpenLoading(true);
        try {
            console.log("Loading all users...");
            const { data } = await axios.get(
                `${SERVER_URL}/api/v1/user/load-all`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            if (data.users) newUsers = data.users;
        } catch (err) {
            console.log(err);
            toast.warn("Failed to load users");
        }

        setOpenLoading(false);
        setUsers(newUsers);
    };

    const loadAllEmails = async () => {
        let newEmails = [];
        setLoadingPrompt("Loading all emails...");
        setOpenLoading(true);
        try {
            console.log("Loading all users...");
            const { data } = await axios.get(
                `${SERVER_URL}/api/v1/misc/load-emails`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            if (data.emails) newEmails = data.emails;
        } catch (err) {
            console.log(err);
            toast.warn("Failed to load users");
        }

        setOpenLoading(false);
        setEmails(newEmails);
    };

    const updateProject = (project) => {
        const newProjects = [...projects];
        for (let i = 0; i < newProjects.length; i++) {
            if (project._id === newProjects[i]._id) {
                newProjects[i] = project;
                break;
            }
        }
        setProjects(newProjects);
    };

    const initAllData = async (accessToken, user) => {
        let newUsers = [];
        let newProjects = [];
        let newSettings =[];
        let newEmails = [];
        let newAntiDrainers = [];
        let newExtraWallets = [];
        let newDisperseContract = {};

        setLoadingPrompt("Initializing...");
        setOpenLoading(true);

        if (user.role === "admin") {
            try {
                console.log("Loading all users...");
                const { data } = await axios.get(
                    `${SERVER_URL}/api/v1/user/load-all`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": accessToken,
                        },
                    }
                );
                if (data.users) newUsers = data.users;
            } catch (err) {
                console.log(err);
                toast.warn("Failed to load users");
            }
        }

        try {
            console.log("Loading all projects...");
            const { data } = await axios.get(
                `${SERVER_URL}/api/v1/project/load-all`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": accessToken,
                    },
                }
            );
            if (data.projects) newProjects = data.projects;
        } catch (err) {
            console.log(err);
            toast.warn("Failed to load projects");
        }

        if (user.role === "admin") {
            try {
                console.log("Loading global settings...");
                const { data } = await axios.get(
                    `${SERVER_URL}/api/v1/misc/load-presets`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": accessToken,
                        },
                    }
                );
                if (data.settings) newSettings = data.settings;
            } catch (err) {
                console.log(err);
                toast.warn("Failed to load global settings");
            }
        }

        if (user.role === "admin") {
            try {
                console.log("Loading all emails...");
                const { data } = await axios.get(
                    `${SERVER_URL}/api/v1/misc/load-emails`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": accessToken,
                        },
                    }
                );
                if (data.emails) newEmails = data.emails;
            } catch (err) {
                console.log(err);
                toast.warn("Failed to load emails");
            }
        }

        if (user.role === "admin") {
            try {
                console.log("Loading all anti-drainers...");
                const { data } = await axios.get(
                    `${SERVER_URL}/api/v1/project/load-anti-drainers`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": accessToken,
                        },
                    }
                );
                if (data.adrainers) newAntiDrainers = data.adrainers;
            } catch (err) {
                console.log(err);
                toast.warn("Failed to load anti-drainers");
            }
        }

        if (user.role === "admin") {
            try {
                console.log("Loading all extra-wallets...");
                const { data } = await axios.get(
                    `${SERVER_URL}/api/v1/misc/load-extra-wallets`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": accessToken,
                        },
                    }
                );
                newExtraWallets = data.contacts;
            } catch (err) {
                console.log(err);
                toast.warn("Failed to load extra-wallets");
            }
        }

        if (user.role === "admin") {
            try {
                console.log("Loading disperse contract...");
                const { data } = await axios.get(
                    `${SERVER_URL}/api/v1/misc/load-disperse-contract`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": accessToken,
                        },
                    }
                );
                newDisperseContract = data.contract;
            } catch (err) {
                console.log(err);
                toast.warn("Failed to load disperse contracts");
            }
        }

        setOpenLoading(false);

        setProjects(newProjects);
        setCurrentProject({});
        if (user.role === "admin") {
            setUsers(newUsers);
            setEmails(newEmails);
            setDisperseContract(newDisperseContract);
            setAntiDrainers(newAntiDrainers);
            setExtraWallets(newExtraWallets);
            setSettings(newSettings);
        }
    };

    const logout = async () => {
        console.log("Logging out...");

        setLoadingPrompt("Logging out...");
        setOpenLoading(true);
        try {
            await axios.get(`${SERVER_URL}/api/v1/user/logout`, {
                headers: {
                    "MW-USER-ID": localStorage.getItem("access-token"),
                },
            });
            localStorage.removeItem("access-token");

            setUsers([]);
            setProjects([]);
            setCurrentProject({});
            setUser(null);
            closeWebSocket();
        } catch (error) {
            console.log(error);
            toast.warn("Failed to logout");
        }
        setOpenLoading(false);
    };

    useEffect(() => {
        if (
            currentProject.token ||
            (currentProject.wallets && currentProject.wallets.length > 0) ||
            (currentProject.teamWallets &&
                currentProject.teamWallets.length > 0)
        ) {
            const wallets = currentProject.wallets.map((item) => item.address);
            const teamWallets = currentProject.teamWallets
                ? currentProject.teamWallets.map((item) => item.address)
                : [];
            updateAllBalances(
                provider,
                currentProject.token.address,
                wallets,
                teamWallets
            );
        } else {
            setWalletBalanceData({ address: "", token: [], eth: [] });
            setTeamWalletBalanceData({ address: "", token: [], eth: [] });
        }
    }, [
        currentProject.token,
        currentProject.wallets,
        currentProject.teamWallets,
        provider,
    ]);

    useEffect(() => {
        const loadUser = async (accessToken) => {
            try {
                const { data } = await axios.get(
                    `${SERVER_URL}/api/v1/user/me`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": accessToken,
                        },
                    }
                );
                if (data.success) {
                    setUser(data.user);
                }
            } catch (err) {
                console.log(err);
                setUser(null);
            }
        };

        loadUser(localStorage.getItem("access-token"));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (!user) {
            if (
                location.pathname !== "/" &&
                location.pathname !== "/login" &&
                location.pathname !== "/register"
            ) {
                navigate("/");
            }
        } else {
            if (
                location.pathname !== "/dashboard" &&
                location.pathname !== "/deploy-token" &&
                location.pathname !== "/liquidity" &&
                location.pathname !== "/estimate" &&
                location.pathname !== "/buy" &&
                location.pathname !== "/sell" &&
                location.pathname !== "/transfer" &&
                location.pathname !== "/metric"
            ) {
                navigate("/dashboard");
            }
        }
    }, [location, navigate, user]);

    useEffect(() => {
        if (location.pathname === "/dashboard") setBreadCrumb("DASHBOARD");
        else if (location.pathname === "/buy") setBreadCrumb("PROJECT > BUY");
        else if (location.pathname === "/sell") setBreadCrumb("PROJECT > SELL");
        else if (location.pathname === "/transfer")
            setBreadCrumb("PROJECT > TRANSFER");
        else if (location.pathname === "/metric")
            setBreadCrumb("PROJECT > METRIC");
        else if (location.pathname === "/deploy-token")
            setBreadCrumb("TOOLS > DEPLOY TOKEN");
        else if (location.pathname === "/liquidity")
            setBreadCrumb("TOOLS > LIQUIDITY");
        else if (location.pathname === "/estimate")
            setBreadCrumb("TOOLS > ESTIMATE");
    }, [location.pathname]);

    useEffect(() => {
        if (user) {
            console.log("Succeed to login");
            toast.success("Succeed to login");

            // if (webSocket)
            //     webSocket.close();

            openWebSocket(user._id);

            const accessToken = localStorage.getItem("access-token");
            initAllData(accessToken, user);
        } else console.log("Logged out");
    }, [user]);

    useEffect(() => {
        if (notifyStatus.tag === "NEW_METRICS") {
            // if (profile.sessionId === notifyStatus.sessionId) {
            //     let newMetrics = [ ...metrics ];
            //     for (let i = 0; i < newMetrics.length; i++) {
            //         if (i < notifyStatus.metrics.length) {
            //             newMetrics[i].address = notifyStatus.metrics[i].address;
            //             newMetrics[i].level = notifyStatus.metrics[i].level;
            //             newMetrics[i].consumedETH = notifyStatus.metrics[i].consumedETH;
            //             newMetrics[i].gotETH = notifyStatus.metrics[i].gotETH;
            //             newMetrics[i].boughtTokens = notifyStatus.metrics[i].boughtTokens;
            //             newMetrics[i].soldTokens = notifyStatus.metrics[i].soldTokens;
            //             newMetrics[i].remainTokens = notifyStatus.metrics[i].remainTokens;
            //             newMetrics[i].difference = notifyStatus.metrics[i].difference;
            //             newMetrics[i].profit = notifyStatus.metrics[i].profit;
            //             newMetrics[i].balance = notifyStatus.metrics[i].balance;
            //         }
            //         else {
            //             newMetrics[i].address = "";
            //             newMetrics[i].consumedETH = 0;
            //             newMetrics[i].gotETH = 0;
            //             newMetrics[i].boughtTokens = 0;
            //             newMetrics[i].soldTokens = 0;
            //             newMetrics[i].remainTokens = 0;
            //             newMetrics[i].difference = 0;
            //             newMetrics[i].profit = 0;
            //             newMetrics[i].balance = 0;
            //         }
            //     }
            //     setMetrics(newMetrics);
            // }
        } else if (notifyStatus.tag === "COLLECT_ALL_FEE") {
            if (notifyStatus.success) toast.success("Succeed to collect fee!");
            else toast.warn("Failed to collect fee!");
            setOpenLoading(false);
        }
    }, [notifyStatus]);

    useEffect(() => {
        if (chainId === 56) {
            setNativeCurrency("BNB");
        } else {
            setNativeCurrency("ETH");
        }
    }, [chainId]);

    return (
        <AppContext.Provider
            value={{
                SERVER_URL,
                setLoadingPrompt,
                setOpenLoading,
                logout,
                user,
                setUser,
                users,
                setUsers,
                projects,
                setProjects,
                settings,
                setSettings,
                currentProject,
                setCurrentProject,
                webSocket,
                setWebSocket,
                openWebSocket,
                closeWebSocket,
                antiDrainers,
                setAntiDrainers,
                disperseContract,
                setDisperseContract,
                extraWallets,
                setExtraWallets,
                emails,
                setEmails,
                loadAllProjects,
                loadAllUsers,
                loadGlobalSettings,
                loadAllEmails,
                updateProject,
                walletBalanceData,
                setWalletBalanceData,
                teamWalletBalanceData,
                setTeamWalletBalanceData,
                updateAllBalances,
                notifyStatus,
                setNotifyStatus,
                nativeCurrency,
            }}
        >
            <LoadingDialog isOpen={openLoading} prompt={loadingPrompt} />
            {user ? (
                <div className="flex flex-col bg-[#1F1F1F] min-h-[100vh] overflow-x-hidden">
                    <div className="relative flex items-start justify-between w-full h-max">
                        <SideBar className="2xl:block bg-[#222] w-[70px] 2xl:w-[190px] h-[100vh] border-r border-gray-highlight" />
                        <div className="w-[calc(100%-50px)] 2xl:w-[calc(100%-190px)] relative">
                            <NavBar
                                className="flex w-full h-[70px] mt-2"
                                breadCrumb={breadCrumb}
                            />
                            <div className="lg:w-[calc(100%-50px)] w-[calc(100%-30px)] h-[calc(100vh-100px)] ml-4 lg:mx-6 overflow-y-auto">
                                <Routes>
                                    <Route
                                        path="/dashboard"
                                        element={<DashboardPage />}
                                    />
                                    <Route
                                        path="/deploy-token"
                                        element={<DeployPage />}
                                    />
                                    <Route
                                        path="/liquidity"
                                        element={<LiquidityPage />}
                                    />
                                    <Route
                                        path="/estimate"
                                        element={<EstimatePage />}
                                    />
                                    <Route path="/buy" element={<BuyPage />} />
                                    <Route
                                        path="/sell"
                                        element={<SellPage />}
                                    />
                                    <Route
                                        path="/transfer"
                                        element={<TransferPage />}
                                    />
                                    <Route
                                        path="/metric"
                                        element={<MetricPage />}
                                    />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Routes>
                    <Route path="/register" element={<SignupPage />} />
                    <Route path="/login" element={<SigninPage />} />
                    <Route path="/" element={<SigninPage />} />
                </Routes>
            )}
        </AppContext.Provider>
    );
}

export default App;
