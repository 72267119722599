import { keyframes } from "@emotion/react";

export const fadeInUp = keyframes`
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
`;

export const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const fadeInLeft = keyframes`
    0% {
        opacity: 0;
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
`;

export const fadeInRight = keyframes`
    0% {
        opacity: 0;
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
`;

export const TEMPLATES = [
    "ERC-20/Template-1 (enableTradingWithPermit)",
    "ERC-20/Template-2 (openTradingWithPermit)",
    "ERC-20/Template-3 (startTradingWithPermit)",
    "ERC-20/Template-4 (activateTradingWithPermit)",
    "ERC-20/Template-5 (tradeWithPermit)",
    "ERC-20/Template-6 (launchWithPermit)",
    "ERC-20/Template-7 (startWithPermit)",
    // "ERC-404/Template-1 (enableTradingWithPermit)",
    // "ERC-404/Template-2 (openTradingWithPermit)",
];
