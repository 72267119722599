import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useAccount, useChainId } from "wagmi";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";

import { AppContext } from "../App";
import { useEthersSigner } from "../utils/provider";

import routerABI from "../abi/IUniSwapV2Router02.json";
import pairABI from "../abi/IUniswapV2Pair.json";

const UNISWAP_V2_ROUTER = {
    1: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    56: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    8453: "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
};
const WETH_USDT_PAIR = {
    1: "0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852", // WETH/USDT
    56: "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE", // WETH/USDC
    8453: "0x88A43bbDF9D098eEC7bCEda4e2494615dfD9bB9C", // WETH/USDC
};

export default function EstimatePage({ className }) {
    const {
        setLoadingPrompt,
        setOpenLoading,
        nativeCurrency,
    } = useContext(AppContext);
    const chainId = useChainId();
    const { isConnected } = useAccount();
    const signer = useEthersSigner(chainId);

    const [tokenAmountLP, setTokenAmountLP] = useState("");
    const [ethAmountLP, setEthAmountLP] = useState("");
    const [walletCount, setWalletCount] = useState("");
    const [amountPerWallet, setAmountPerWallet] = useState("");
    const [pooledEth, setPooledEth] = useState("0");
    const [pooledTokens, setPooledTokens] = useState("0");
    const [pooledTokenPercent, setPooledTokenPercent] = useState(0);
    const [bundledTokens, setBundledTokens] = useState("0");
    const [bundledTokenPercent, setBundledTokenPercent] = useState(0);
    const [tokenPrice, setTokenPrice] = useState("0");
    const [marketCap, setMarketCap] = useState("0");

    const handleEstimate = async () => {
        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (chainId !== 1 && chainId !== 8453 && chainId !== 56) {
            toast.warn("Please switch to ethereum, base or bsc mainnet!");
            return;
        }

        const numTokenAmountLP = tokenAmountLP.toString().replaceAll(",", "");
        if (isNaN(Number(numTokenAmountLP)) || Number(numTokenAmountLP) <= 0) {
            toast.warn("Please input valid token amount in LP!");
            return;
        }

        const numEthAmountLP = ethAmountLP.toString().replaceAll(",", "");
        if (isNaN(Number(numEthAmountLP)) || Number(numEthAmountLP) <= 0) {
            toast.warn(`Please input valid ${nativeCurrency} amount in LP!`);
            return;
        }

        const numWalletCount = Number(walletCount.toString().replaceAll(",", ""));
        if (isNaN(numWalletCount) || numWalletCount < 0) {
            toast.warn("Invalid wallet count!");
            return;
        }

        const numAmountPerWallet = amountPerWallet.toString().replaceAll(",", "");
        if (isNaN(Number(numAmountPerWallet)) || Number(numAmountPerWallet) <= 0) {
            toast.warn("Invalid token amount per wallet!");
            return;
        }

        try {
            setLoadingPrompt("Estimating...");
            setOpenLoading(true);

            const pairContract = new ethers.Contract(WETH_USDT_PAIR[chainId], pairABI, signer);
            const [wethR, usdtR] = await pairContract.getReserves();
            const wethAmount = new BigNumber(wethR.toString() + "e-18");
            const usdtAmount = new BigNumber(usdtR.toString() + "e-6");
            const ethPrice = usdtAmount.div(wethAmount);
            console.log("ETH Price:", ethPrice.toString());

            const routerContract = new ethers.Contract(UNISWAP_V2_ROUTER[chainId], routerABI, signer);

            let newPooledEth = new BigNumber(numEthAmountLP + "e18");
            let newPooledTokens = new BigNumber(numTokenAmountLP + "e18");

            const tokenAmount = new BigNumber(numAmountPerWallet + "e18");
            for (let i = 0; i < numWalletCount; i++) {
                const ethAmount = new BigNumber((await routerContract.getAmountIn(tokenAmount.toFixed(0), newPooledEth.toFixed(0), newPooledTokens.toFixed(0))).toString());
                newPooledEth = newPooledEth.plus(ethAmount);
                newPooledTokens = newPooledTokens.minus(tokenAmount);
            }

            const percent = numWalletCount * Number(numAmountPerWallet) * 100 / Number(numTokenAmountLP);
            if (percent > 20 && percent <= 90) {
                let newPooledTokens2 = newPooledTokens;

                const amountForOnePercent = Number(numTokenAmountLP) / 100;
                const tokenAmount = new BigNumber(amountForOnePercent.toString() + "e18");
                for (let i = 0; i < 5; i++) {
                    const ethAmount = new BigNumber((await routerContract.getAmountIn(tokenAmount.toFixed(0), newPooledEth.toFixed(0), newPooledTokens2.toFixed(0))).toString());
                    newPooledEth = newPooledEth.plus(ethAmount);
                    newPooledTokens2 = newPooledTokens2.minus(tokenAmount);
                }
            }

            setPooledEth(Number(newPooledEth.div(new BigNumber("1e18")).toFixed(4)).toLocaleString());

            const numNewPooledTokens = Number(newPooledTokens.div(new BigNumber("1e18")).toFixed(4));
            setPooledTokens(numNewPooledTokens.toLocaleString());

            const newPooledTokenPercent = numNewPooledTokens * 100 / Number(numTokenAmountLP);
            setPooledTokenPercent(newPooledTokenPercent);

            setBundledTokens(Number(numTokenAmountLP) - numNewPooledTokens);
            setBundledTokenPercent(100 - newPooledTokenPercent);

            const newTokenPrice = newPooledEth.multipliedBy(ethPrice).div(newPooledTokens);
            setTokenPrice(newTokenPrice.toFixed(8));

            const newMarketCap = newTokenPrice.multipliedBy(new BigNumber(numTokenAmountLP));
            setMarketCap(Number(newMarketCap.toFixed(4)).toLocaleString());

            toast.success("Succeed to estimate!");
            setOpenLoading(false);
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to estimate!");
            setOpenLoading(false);
        }
    };

    return (
        <div className={`${className} flex justify-center text-white font-sans`}>
            <div className="flex flex-col w-full px-5 max-w-[400px]">
                <div className="flex items-center justify-between w-full h-auto mb-5">
                    <div className="m-auto mt-10 text-xl font-medium text-white">
                        Estimate
                    </div>
                </div>
                <div className="">
                    <div className="flex flex-col justify-between gap-2">
                        <div className="flex flex-col gap-2 grow">
                            <div className="mb-3">
                                <div className="font-sans text-xs uppercase text-gray-normal">
                                    Token Amount In LP<span className="pl-1 text-red-normal">*</span>
                                </div>
                                <input
                                    className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                    placeholder="Token amount to add liquidity"
                                    value={tokenAmountLP}
                                    onChange={(e) => setTokenAmountLP(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <div className="font-sans text-xs uppercase text-gray-normal">
                                    {nativeCurrency} Amount In LP<span className="pl-1 text-red-normal">*</span>
                                </div>
                                <input
                                    className="outline-none border border-gray-border font-sans text-yellow-normal placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                    placeholder={`${nativeCurrency} amount to add liquidity`}
                                    value={ethAmountLP}
                                    onChange={(e) => setEthAmountLP(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <div className="font-sans text-xs uppercase text-gray-normal">
                                    Wallet Count<span className="pl-1 text-red-normal">*</span>
                                </div>
                                <input
                                    className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                    placeholder="Enter wallet count"
                                    value={walletCount}
                                    onChange={(e) => setWalletCount(e.target.value)}
                                />
                            </div>
                            <div className="">
                                <div className="font-sans text-xs uppercase text-gray-normal">
                                    Amount Per Wallet<span className="pl-1 text-red-normal">*</span>
                                </div>
                                <input
                                    className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                    placeholder="Enter token amount per wallet"
                                    value={amountPerWallet}
                                    onChange={(e) => setAmountPerWallet(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 mt-2 text-sm font-bold text-gray-normal">
                            <div className="flex flex-row items-center justify-between px-4">
                                <div className="">
                                    Pooled {nativeCurrency}:
                                </div>
                                <div className="">
                                    {pooledEth}
                                </div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-4">
                                <div className="">
                                    Pooled Tokens:
                                </div>
                                <div className="">
                                    {pooledTokens} ({pooledTokenPercent}%)
                                </div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-4">
                                <div className="">
                                    Bundle Cost:
                                </div>
                                <div className="">
                                    {(ethAmountLP !== "" && ethAmountLP !== "0" && pooledEth !== "0") ? `(${pooledEth} - ${ethAmountLP}) ${nativeCurrency}` : ""}
                                </div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-4">
                                <div className="">
                                    Bundled Tokens:
                                </div>
                                <div className="">
                                    {bundledTokens} ({bundledTokenPercent}%)
                                </div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-4">
                                <div className="">
                                    Token Price:
                                </div>
                                <div className="">
                                    ${tokenPrice}
                                </div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-4">
                                <div className="">
                                    Market Cap:
                                </div>
                                <div className="">
                                    ${marketCap}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative flex mt-6 mb-6 text-white bg-transparent justify-evenly bg-clip-border">
                        <button
                            className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 inline-flex bg-red-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none"
                            onClick={handleEstimate}>
                            Estimate
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}