// import { formatGwei } from "viem";

export const numberWithCommas = (x, digits = 3) => {
    return parseFloat(x).toLocaleString(undefined, { maximumFractionDigits: digits });
};

// Send data between components
export const EventBus = {
    on(event, callback) {
        document.addEventListener(event, (e) => callback(e.detail));
    },
    dispatch(event, data) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
        document.removeEventListener(event, callback);
    },
};

export const ellipsisAddress = (address, isLong) => {
    if (!isLong)
        return address?.toString()?.slice(0, 9) + "..." + address?.toString()?.slice(-7);
    return address?.toString()?.slice(0, 14) + "..." + address?.toString()?.slice(-12);
}

export const isValidAddress = (addr) => {
    const regex = /^(0x)?[0-9a-fA-F]{40}$/;
    return regex.test(addr);
};

export const formatNumber = (number) => {
    let suffix = '';
    let formattedNumber = number;

    if (number >= 1e6) {
        suffix = 'M';
        formattedNumber = number / 1e6;
    }
    else if (number >= 1e3) {
        suffix = 'k';
        formattedNumber = number / 1e3;
    }
    return (formattedNumber && formattedNumber > 0) ? `${parseFloat(formattedNumber)?.toFixed(2)}${suffix}` : 0;
}

export const sleep = (ms) => new Promise(r => setTimeout(r, ms));
