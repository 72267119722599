import { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdDashboard, MdOutlineSell } from "react-icons/md";
import { RiProjectorFill } from "react-icons/ri";
import { FaTools } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { BiSolidPurchaseTag, BiTransferAlt } from "react-icons/bi";
import { TbRulerMeasure } from "react-icons/tb";
import { GrDeploy } from "react-icons/gr";
import { PiSwimmingPool } from "react-icons/pi";
import { CiCalculator1 } from "react-icons/ci";

import { AppContext } from "../App";

export default function SideBarComponent({ className }) {
    const { currentProject } = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [openToolsMenu, setOpenToolsMenu] = useState(false);
    const [openProjectMenu, setOpenProjectMenu] = useState(false);

    useEffect(() => {
        if (location.pathname === "/deploy-token" || location.pathname === "/liquidity" || location.pathname === "/estimate") {
            setOpenToolsMenu(true);
            setOpenProjectMenu(false);
        }
        else if (location.pathname === "/buy" || location.pathname === "/sell" || location.pathname === "/transfer" || location.pathname === "/metric") {
            setOpenProjectMenu(true);
            setOpenToolsMenu(false);
        }
    }, [location.pathname]);

    const handleCollapse = (e, menuName) => {
        e.stopPropagation();
        if (menuName === "tools") {
            const newOpenToolsMenu = !openToolsMenu;
            setOpenToolsMenu(newOpenToolsMenu);
            if (newOpenToolsMenu)
                setOpenProjectMenu(false);
        }
        else if (menuName === "project") {
            const newOpenProjectMenu = !openProjectMenu;
            setOpenProjectMenu(newOpenProjectMenu);
            if (newOpenProjectMenu)
                setOpenToolsMenu(false);
        }
    };

    const handleBuy = () => {
        if (currentProject._id)
            navigate("/buy");
    };

    const handleSell = () => {
        if (currentProject._id)
            navigate("/sell");
    };

    const handleTransfer = () => {
        if (currentProject._id)
            navigate("/transfer");
    };

    const handleMetric = () => {
        if (currentProject._id)
            navigate("/metric");
    };

    return (
        <div className={`${className} font-sans flex-col gap-2 items-center text-gray-normal relative`}>
            <img src={`/logo.png`} className="hidden 2xl:block w-full max-w-[147px] max-h-[35px] mt-5 m-auto cursor-pointer" alt="" onClick={() => navigate("/")} />
            <img className="absolute bottom-0 object-contain w-full" src="/assets/coin.png" alt="coin" />
            <div className={`w-[50px] 2xl:w-full h-9 uppercase hover:bg-[rgba(255,255,255,0.1)] flex justify-center text-sm 2xl:justify-start mx-auto 2xl:px-5 gap-4 items-center mt-5 cursor-pointer ${location.pathname === "/dashboard" ? "bg-gray-highlight text-white font-medium" : ""} `} onClick={() => navigate("/dashboard")}>
                <MdDashboard className="w-[18px] h-[18px] relative" />
                <div className="hidden text-sm 2xl:block">
                    Dashboard
                </div>
            </div>
            <div className={`w-[50px] 2xl:w-full h-9 uppercase hover:bg-[rgba(255,255,255,0.1)] flex justify-center text-sm 2xl:justify-start mx-auto 2xl:px-5 gap-4 items-center mt-[1px] cursor-pointer ${(location.pathname === "/buy" || location.pathname === "/sell" || location.pathname === "/transfer" || location.pathname === "/metric") ? "bg-[rgba(255,255,255,0.1)]" : ""}`} onClick={(e) => handleCollapse(e, "project")}>
                <RiProjectorFill className="w-[18px] h-[18px] relative" />
                <div className="items-center justify-between hidden w-[calc(100%-34px)] 2xl:flex">
                    <div className="w-full text-left">
                        Project
                    </div>
                    <IoIosArrowDown className={`w-4 h-full ${openProjectMenu ? "transform rotate-180" : ""}`} />
                </div>
            </div>
            {
                openProjectMenu &&
                <div className="">
                    <div className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] ${currentProject._id ? "cursor-pointer" : "cursor-not-allowed"} ${location.pathname === "/buy" ? "bg-gray-highlight text-white font-medium" : ""}`} onClick={handleBuy}>
                        <BiSolidPurchaseTag className="w-[18px] h-[18px]" />
                        <div className="hidden text-sm 2xl:flex">
                            Buy
                        </div>
                    </div>
                    <div className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px]  ${currentProject._id ? "cursor-pointer" : "cursor-not-allowed"} ${location.pathname === "/sell" ? "bg-gray-highlight text-white font-medium" : ""}  `} onClick={handleSell}>
                        <MdOutlineSell className="w-[18px] h-[18px]" />
                        <div className="hidden text-sm 2xl:flex">
                            Sell
                        </div>
                    </div>
                    <div className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] ${currentProject._id ? "cursor-pointer" : "cursor-not-allowed"} ${location.pathname === "/transfer" ? "bg-gray-highlight text-white font-medium" : ""} `} onClick={handleTransfer}>
                        <BiTransferAlt className="w-[18px] h-[18px]" />
                        <div className="hidden text-sm 2xl:flex">
                            Transfer
                        </div>
                    </div>
                    <div className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] ${currentProject._id ? "cursor-pointer" : "cursor-not-allowed"} ${location.pathname === "/metric" ? "bg-gray-highlight text-white font-medium" : ""} `} onClick={handleMetric}>
                        <TbRulerMeasure className="w-[18px] h-[18px]" />
                        <div className="hidden text-sm 2xl:flex">
                            Metric
                        </div>
                    </div>
                </div>
            }
            <div className={`w-[50px] 2xl:w-full h-9 uppercase hover:bg-[rgba(255,255,255,0.1)] flex justify-center text-sm 2xl:justify-start mx-auto 2xl:px-5 gap-4 items-center mt-[1px] cursor-pointer ${(location.pathname === "/deploy-token" || location.pathname === "/liquidity" || location.pathname === "/estimate") ? "bg-[rgba(255,255,255,0.1)]" : ""}`} onClick={(e) => handleCollapse(e, "tools")}>
                <FaTools className="w-[18px] h-[18px] relative" />
                <div className="items-center justify-between hidden w-[calc(100%-34px)] 2xl:flex">
                    <div className="w-full text-left">
                        Tools
                    </div>
                    <IoIosArrowDown className={`w-4 h-full ${openToolsMenu ? "transform rotate-180" : ""}`} />
                </div>
            </div>
            {
                openToolsMenu &&
                <div className="">
                    <div className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] cursor-pointer ${location.pathname === "/deploy-token" ? "bg-gray-highlight text-white font-medium" : ""}`} onClick={() => navigate("/deploy-token")}>
                        <GrDeploy className="w-[18px] h-[18px]" />
                        <div className="hidden text-sm 2xl:flex">
                            Deploy Token
                        </div>
                    </div>
                    <div className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] cursor-pointer ${location.pathname === "/liquidity" ? "bg-gray-highlight text-white font-medium" : ""}`} onClick={() => navigate("/liquidity")}>
                        <PiSwimmingPool className="w-[18px] h-[18px]" />
                        <div className="hidden text-sm 2xl:flex">
                            Liquidity
                        </div>
                    </div>
                    <div className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] cursor-pointer ${location.pathname === "/estimate" ? "bg-gray-highlight text-white font-medium" : ""}`} onClick={() => navigate("/estimate")}>
                        <CiCalculator1 className="w-[18px] h-[18px]" />
                        <div className="hidden text-sm 2xl:flex">
                            Estimate
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
