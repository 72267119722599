import { useState } from "react";
import Modal from "./Modal";

export default function BlackListDialog({ isOpen, tokenAddress, onAdd, onRemove, onCancel }) {
    const [addresses, setAddresses] = useState("");
    const [addressList, setAddressList] = useState([]);

    const handleChange = (e) => {
        const newAddressList = e.target.value.split(/[\s,\t\n]+/);
        setAddresses(newAddressList.join("\r\n"));
        setAddressList(newAddressList.filter(item => item !== ""));
    };

    const handleAdd = () => {
        if (addressList.length > 0)
            onAdd(addressList);
    };

    const handleRemove = () => {
        if (addressList.length > 0)
            onRemove(addressList);
    }

    const handleCancel = () => {
        setAddresses("");
        onCancel();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleCancel}>
            <div className="flex flex-col pt-5 w-[640px] font-sans">
                <div className="flex items-center justify-start w-full h-auto px-5 py-3 rounded-t-md bg-gray-highlight">
                    <div className="font-sans text-sm font-medium text-white uppercase">
                        Set Blacklist
                    </div>
                </div>
                <div className="items-center w-full h-auto px-5 py-5 md:py-0 bg-gray-dark rounded-b-md">
                    <div className="mt-5">
                        <div className="font-sans text-xs uppercase text-gray-normal">
                            Token Address<span className="pl-1 text-red-normal">*</span>
                        </div>
                        <input
                            className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full mt-1"
                            placeholder="Enter address list"
                            disabled
                            value={tokenAddress}
                        />
                    </div>
                    <div className="mt-5">
                        <div className="font-sans text-xs uppercase text-gray-normal">
                            Address List<span className="pl-1 text-red-normal">*</span>
                        </div>
                        <textarea
                            className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full !min-h-32 !max-h-80 mt-1"
                            placeholder="Enter address list"
                            value={addresses}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex items-center justify-center gap-5 my-5">
                        <button
                            className="pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-red-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                            onClick={handleAdd}>
                            Add to Blacklist
                        </button>
                        <button
                            className="pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-red-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                            onClick={handleRemove}>
                            Remove from Blacklist
                        </button>
                        <button
                            className="pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-100 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                            onClick={handleCancel}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
